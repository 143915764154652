var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","sm":"5"}},[_c('v-card',{staticStyle:{"font-size":"16px"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Add a document to the system ")]),_c('v-spacer')],1),_c('validation-observer',{ref:"addDocumentRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',{staticStyle:{"padding":"0 30px"},on:{"submit":function($event){$event.preventDefault();return _vm.submitCreateDocument(reset)}}},[_c('validation-provider',{attrs:{"name":"Document Title","rules":"required|max:256|min:3|title_documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Enter Document Title","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories_doc,"item-text":"name","item-value":"id","error-messages":errors,"label":"Choose Category","required":""},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"accept":".pdf","prepend-icon":"mdi-file-pdf-box","label":"Add Documents","error-messages":errors,"required":""},model:{value:(_vm.form.document),callback:function ($$v) {_vm.$set(_vm.form, "document", $$v)},expression:"form.document"}})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid,"text":""}},[_vm._v(" Submit ")])],1)],1)]}}])})],1)],1)],1),_c('v-divider',{staticStyle:{"margin-top":"20px"}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"dense":"","headers":_vm.headers,"items":_vm.loading ? [] : _vm.corporative_doc,"footer-props":{
            'items-per-page-options': [10, 25, 50, 100, 150, 200],
        },"server-items-length":_vm.count_doc},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date_created",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(_vm.dataDayYear(item.date_created))+" ")]),_vm._v(" "+_vm._s(_vm.dataTime(item.date_created))+" ")]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.show_edits),callback:function ($$v) {_vm.show_edits=$$v},expression:"show_edits"}},[_c('v-card',{staticStyle:{"font-size":"16px"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Edit a document in the system ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.show_edits = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('validation-observer',{ref:"editDocumentRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('form',{staticStyle:{"padding":"0 30px"},on:{"submit":function($event){$event.preventDefault();return _vm.submitEditDocument()}}},[_c('validation-provider',{attrs:{"name":"Document Title","rules":"required|max:256|min:3|title_documents"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Enter New Document Title","required":""},model:{value:(_vm.edit_form.title),callback:function ($$v) {_vm.$set(_vm.edit_form, "title", $$v)},expression:"edit_form.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories_doc,"item-text":"name","item-value":"id","error-messages":errors,"label":"Choose New Category","required":""},model:{value:(_vm.edit_form.category),callback:function ($$v) {_vm.$set(_vm.edit_form, "category", $$v)},expression:"edit_form.category"}})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.show_edits = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","disabled":invalid,"text":""}},[_vm._v(" Change ")])],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.show_delete),callback:function ($$v) {_vm.show_delete=$$v},expression:"show_delete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete document? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.show_delete = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.submitDeleteItem}},[_vm._v(" Yes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }