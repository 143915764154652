<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'AddDocuments',

    data: () => ({
        loading: false,
        show_edits: false,
        show_delete: false,

        form: {
            title: '',
            category: '',
            document: [],
        },

        options: {
            page: 1,
            itemsPerPage: 10,
        },

        edit_form: {
            title: '',
            category: '',
            document_id: '',
        },

        delete_item: {
            document_id: '',
        },
    }),

    watch: {
        options: {
            handler(val) {
                this.paginationTable(val);
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions('addDocuments', [
            'addCorporativeDocument',
            'updateCorporativeDocument',
            'deleteCorporativeDocument',
            'fetchAllDocumentsCategories',
            'fetchAllCorporativeDocuments',
        ]),

        editItem({ title, category, id }) {
            this.show_edits = true;
            this.edit_form.title = title;
            this.edit_form.category = category;
            this.edit_form.document_id = id;
        },

        deleteItem({ id }) {
            this.show_delete = true;
            this.delete_item.document_id = id;
        },

        submitDeleteItem() {
            this.deleteCorporativeDocument({ ...this.delete_item })
                .then(code => {
                    this.$alertify.success('Document deleted successfully');
                })
                .catch(({ response: { data } }) => {
                    this.$alertify.error(data.message);
                });

            this.goToFirstPage();
            this.paginationTable({ itemsPerPage: 10, page: 1 });
            this.delete_item = {};
            this.show_delete = false;
        },

        async paginationTable(event) {
            this.loading = true;
            const { itemsPerPage, page } = event;
            const payload = {
                rows: itemsPerPage,
                pagination: page,
            };
            await this.fetchAllCorporativeDocuments(payload);
            this.loading = false;
        },

        async submitCreateDocument(reset) {
            const validate = await this.$refs.addDocumentRef.validate();
            if (validate) {
                await this.addCorporativeDocument({ ...this.form })
                    .then(() => {
                        this.$alertify.success('Document added successfully');
                    })
                    .catch(({ response: { data } }) => {
                        this.$alertify.error(data.message);
                    });
            }

            this.goToFirstPage();
            this.paginationTable({ itemsPerPage: 10, page: 1 });
            this.form = {};

            if (reset) {
                reset();
            }
        },

        async submitEditDocument() {
            const validate = await this.$refs.editDocumentRef.validate();
            if (validate) {
                await this.updateCorporativeDocument({ ...this.edit_form })
                    .then(code => {
                        this.$alertify.success(
                            'Document modified successfully',
                        );
                    })
                    .catch(({ response: { data } }) => {
                        this.$alertify.error(data.message);
                    });
            }

            this.goToFirstPage();
            this.paginationTable({ itemsPerPage: 10, page: 1 });

            this.show_edits = false;
        },

        goToFirstPage() {
            this.$set(this.options, 'page', 1);
            this.$set(this.options, 'itemsPerPage', 10);
        },

        dataDayYear(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formatter.format(new Date(+time * 1000));
        },

        dataTime(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('en-AU', {
                timeStyle: 'medium',
                hour12: false,
            });
            return formatter.format(new Date(+time * 1000));
        },
    },

    computed: {
        ...mapGetters('addDocuments', {
            categories_doc: 'getCategoriesDocuments',
            corporative_doc: 'getAllCorporativeDocuments',
            count_doc: 'getCountCorporativeDocuments',
        }),

        headers() {
            return [
                {
                    text: 'Form Title',
                    value: 'title',
                    sortable: false,
                },
                {
                    text: 'Request Date',
                    value: 'date_created',
                    sortable: false,
                },
                {
                    text: 'Category',
                    value: 'category_name',
                    sortable: false,
                },
                {
                    text: 'Edit',
                    value: 'edit',
                    align: 'center',
                    sortable: false,
                },
            ];
        },
    },

    async created() {
        await this.fetchAllDocumentsCategories();
    },
};
</script>

<template>
    <div>
        <v-row justify="center">
            <v-col cols="8" sm="5">
                <v-card style="font-size: 16px;">
                    <v-toolbar flat dark color="primary">
                        <v-toolbar-title>
                            Add a document to the system
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <validation-observer
                        ref="addDocumentRef"
                        v-slot="{ invalid, reset }"
                    >
                        <form
                            @submit.prevent="submitCreateDocument(reset)"
                            style="padding:0 30px"
                        >
                            <validation-provider
                                v-slot="{ errors }"
                                name="Document Title"
                                rules="required|max:256|min:3|title_documents"
                            >
                                <v-text-field
                                    v-model="form.title"
                                    :error-messages="errors"
                                    label="Enter Document Title"
                                    required
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="Category"
                                rules="required"
                            >
                                <v-select
                                    v-model="form.category"
                                    :items="categories_doc"
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="errors"
                                    label="Choose Category"
                                    required
                                ></v-select>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="Document"
                                rules="required"
                            >
                                <v-file-input
                                    v-model="form.document"
                                    accept=".pdf"
                                    prepend-icon="mdi-file-pdf-box"
                                    label="Add Documents"
                                    :error-messages="errors"
                                    required
                                ></v-file-input>
                            </validation-provider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    type="submit"
                                    color="primary"
                                    :disabled="invalid"
                                    text
                                >
                                    Submit
                                </v-btn>
                            </v-card-actions>
                        </form>
                    </validation-observer>
                </v-card>
            </v-col>
        </v-row>

        <v-divider style="margin-top: 20px"></v-divider>

        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="options"
            dense
            :headers="headers"
            :items="loading ? [] : corporative_doc"
            :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, 150, 200],
            }"
            :server-items-length="count_doc"
        >
            <template v-slot:[`item.date_created`]="{ item }">
                <span style="margin-right: 10px">
                    {{ dataDayYear(item.date_created) }}
                </span>
                {{ dataTime(item.date_created) }}
            </template>

            <template v-slot:[`item.edit`]="{ item }">
                <v-icon class="mr-2" color="primary" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="show_edits" persistent max-width="500">
            <v-card style="font-size: 16px;">
                <v-toolbar flat dark color="primary">
                    <v-toolbar-title>
                        Edit a document in the system
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="show_edits = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <validation-observer ref="editDocumentRef" v-slot="{ invalid }">
                    <form
                        @submit.prevent="submitEditDocument()"
                        style="padding:0 30px"
                    >
                        <validation-provider
                            v-slot="{ errors }"
                            name="Document Title"
                            rules="required|max:256|min:3|title_documents"
                        >
                            <v-text-field
                                v-model="edit_form.title"
                                :error-messages="errors"
                                label="Enter New Document Title"
                                required
                            ></v-text-field>
                        </validation-provider>

                        <validation-provider
                            v-slot="{ errors }"
                            name="Category"
                            rules="required"
                        >
                            <v-select
                                v-model="edit_form.category"
                                :items="categories_doc"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                label="Choose New Category"
                                required
                            ></v-select>
                        </validation-provider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="show_edits = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                type="submit"
                                color="primary"
                                :disabled="invalid"
                                text
                            >
                                Change
                            </v-btn>
                        </v-card-actions>
                    </form>
                </validation-observer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="show_delete" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Delete document?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="show_delete = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="submitDeleteItem"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
